//@import "compass/css3";

$boxes: 13;



@for $i from 1 through $boxes {
  @keyframes anim-#{$i} {
    0% {
      top: 50%;
      left: 50%;
    }
    100%{
      top: random(300)+px;
      left: random(400)+px;
    }
  }

  div.box{
    &:nth-child(#{$i}){
      left: $i * 10px;
      animation-duration: 1.9s;
      animation-name: anim-#{$i};
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0,1,.46,.95);
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
      background: #b90000;
    }
  }
}